export const sectionConfig = {
  desktops: {
    statusBar: 'StatusBar'
  },
  desktopsnew: {
    statusBar: 'StatusBar'
  },
  deployment: {
    statusBar: 'DeploymentStatusBar'
  },
  deployments: {
    statusBar: 'DeploymentsStatusBar'
  },
  images: {
    statusBar: 'ImagesStatusBar'
  },
  desktopsBookingCalendar: {
    statusBar: 'StatusBar'
  },
  default: {
    statusBar: 'StatusBar'
  }
}
