<template>
  <b-container id="login" fluid class="h-100 w-100 pt-5 pt-md-0" align="center">
    <b-row class="text-center">
      <b-col cols="4"></b-col>
      <b-col cols="4" class="d-flex flex-column">
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <p align="center"><img src="https://www.nielitchennai.edu.in/cloud/nielit.svg" alt="NIELIT" width="185px" height="90px"></p>
            <!-- Spacer -->
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <b-row class="justify-content-center mb-md-3" style="height: 2rem"/>
            <!-- Logo display -->
            <b-row class="justify-content-center mb-3">
              <h1 v-if="show_login_extras">
                Virtual Lab Access
              </h1>
            </b-row>
            <!-- Category by path display -->
            <b-row v-if="category_by_path" class="ml-2 mt-2">
              <h3>{{ category_name }}</h3>
            </b-row>
            <!-- Language selection -->
            <b-row>
              <Language v-if="show_login_extras" class="ml-3 mt-2 mt-md-4 mb-3"/>
            </b-row>
            <!-- Login form -->
            <b-form v-if="show_login_form" class="m-0" @submit.prevent="login('form')">
              <!-- Error message -->
              <b-alert v-model="showDismissibleAlert" dismissible variant="danger">
                {{ getPageErrorMessage }}
              </b-alert>
              <!-- Category selection -->
              <b-form-select v-if="!category_by_path && getCategories.length > 1" ref="select_category" v-model="category" class="mb-3" size="md" required :options="categories_select">
                <template #first>
                  <b-form-select-option value="" disabled>
                    {{ $t('views.login.form.select-category') }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-input v-model="usr" type="text" required :placeholder="$t('views.login.form.usr')"/>
              <b-form-input v-model="pwd" type="password" required :placeholder="$t('views.login.form.pwd')"/>
              <b-button type="submit" size="lg" class="btn-green w-100 rounded-pill mt-2 mt-md-5">
                {{ $t('views.login.form.login') }}
              </b-button>
            </b-form>
       <b-col cols="4"></b-col>
       </b-col>
     </b-row>
   </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { authenticationSegment } from '@/shared/constants'
export default {
  name: 'Login',
  components: { },
  data () {
    return {
      loading: false,
      usr: '',
      pwd: '',
      window: window,
      error: '',
      showDismissibleAlert: false,
      category: ''
    }
  },
  computed: {
    ...mapGetters([
      'getCategories',
      'getProviders',
      'getPageErrorMessage'
    ]),
    categories_select () {
      return this.getCategories.map(category =>
        ({
          value: category.id,
          text: category.name
        })
      )
    },
    category_by_path () {
      return this.$route.params.category !== undefined
    },
    category_name () {
      let name = ''
      this.getCategories.forEach(category => {
        if (this.category === category.id) {
          name = category.name
        }
      })
      if (!name) {
        name = this.category
      }
      return name
    },
    show_login_form () {
      return this.getCategories.length || this.category_by_path
    },
    show_login_providers () {
      return this.show_login_form && this.getProviders.length
    },
    show_login_extras () {
      return this.show_login_form || this.show_login_providers
    }
  },
  watch: {
    category: function () {
      if (!this.category_by_path) {
        localStorage.category = this.category
      }
    }
  },
  beforeMount: async function () {
    if (localStorage.token) {
      this.$router.push({ name: 'desktops' })
    }
    this.$store.dispatch('removeAuthorizationCookie')
    this.$store.dispatch('fetchProviders')
    this.$store.dispatch('fetchCategories').then(() => {
      let defaultCategory = ''
      if (this.getCategories.length === 1) {
        defaultCategory = this.getCategories[0].id
      }
      if (this.category_by_path) {
        this.category = this.$route.params.category
      } else {
        if (this.getCategories.map(i => i.id).includes(localStorage.category)) {
          this.category = localStorage.category
        } else {
          this.category = defaultCategory
        }
      }
    })
    if (this.$route.query.error) {
      this.$store.dispatch('parseErrorFromQuery', this.$route.query.error)
      this.showDismissibleAlert = true
    }
  },
  methods: {
    login (provider) {
      if (provider === 'form') {
        this.loading = true
        const data = new FormData()
        data.append('category_id', this.category)
        data.append('provider', provider)
        data.append('username', this.usr)
        data.append('password', this.pwd)
        this.$store
          .dispatch('login', data)
          .then(() => {})
          .catch(err => {
            console.log(err)
            this.showDismissibleAlert = true
            this.loading = false
          })
      } else {
        if (this.category) {
          window.location = `${window.location.protocol}//${window.location.host}${authenticationSegment}/login?provider=${provider}&category_id=${this.category}&redirect=/`
        } else {
          this.$refs.select_category.$el.reportValidity()
        }
      }
    }
  }
}
</script>

<style scoped>
#login {
  text-align: center;
}
#powered-by {
  margin: 4rem;
}
#isard-logo {
  width: 3rem;
  margin: -3rem 0.5rem 0 0.5rem;
}
#login form {
  margin: 5px;
}
#login form input {
  margin-bottom: 18px;
}
.login-btn {
  margin: 10px;
}
.login-btn svg {
  margin-right: 10px;
}
/* background -> brand color; border -> background: darken(brand color, 5%); */
.btn-github {
  color: #fff !important;
  background-color: #333 !important;
  border-color: #262626 !important;
}
.btn-google {
  color: #fff !important;
  background-color: #4285f4 !important;
  border-color: #2a75f3 !important;
}
#powered-by a {
  color: inherit !important;
  text-decoration: none !important;
}
.w-100 {
    width: 70%!important;
}
</style>
