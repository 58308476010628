<template>
    <b-card class='border-0 mx-3 pb-4'>
      <b-skeleton-img></b-skeleton-img>
      <br/>
      <b-skeleton width="85%"></b-skeleton>
      <br/>
      <b-skeleton width="55%"></b-skeleton>
      <br/>
      <div class='d-flex flex-row mt-3'>
        <b-skeleton type="input" width="70%"></b-skeleton>
        <b-skeleton type="button" width="40%" class="ml-2"></b-skeleton>
      </div>
    </b-card>
</template>

<script>
export default {}
</script>
