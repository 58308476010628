<template>
    <div class='border-0 mx-3 pb-2'>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <br/>
    </div>
</template>

<script>
export default {}
</script>
